import React, { useState, useEffect } from 'react';
import Modal from '../utils/Modal'; 

const ActivarResto = ({ isModalOpen, closeModal, cuitRestaurante }) => {
  const [codigo, setCodigo] = useState('');
  const [isActivated, setIsActivated] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(''); 

//   useEffect(() => {
//     console.log("Estado de isModalOpen:", isModalOpen);
//   }, [isModalOpen]);

//   useEffect(() => {
//     console.log("Estado de isActivated:", isActivated);
//   }, [isActivated]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(''); // Limpiar mensaje de error en cada envío
    try {
      const formData = new URLSearchParams();
      formData.append('codigo', codigo);
      formData.append('cuit_restaurante', cuitRestaurante);
  
      const response = await fetch('https://tippit-qr.com:8000/activar_restaurante/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(), 
      });
  
      if (response.ok) {
        setIsActivated(true); 
        console.log('Restaurante activado exitosamente');
      } else {
        // Maneja el error
        // const errorText = await response.text();
        setErrorMessage('El codigo ingresado es incorrecto');
      }
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      setErrorMessage('Error al enviar el formulario: ' + error.message);
    }
  };

  return (
    <div>
      <div id="modal-root"></div>
      
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {!isActivated ? (
          <div className="max-w-[600px] p-4">
            <h2 className='text-xl font-bold mb-1'>Parece que aún no has activado tu cuenta!</h2>
            <h3>Activa tu cuenta con el código que viene en el paquete que te enviamos.</h3>
            <p className='my-2 text-gray-500'>(Mientras tanto, puedes ir preparando a tu personal vinculándolos a tu restaurante, organizando cómo se dividirá la propina entre ellos.)</p>

            <p className='mb-3 bg-gray-100 px-2 text-gray-700 py-1 rounded-md mt-5'>Activar Paquete:</p>
            
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="codigo" className='text-sm ml-2 block'>Código de activación:</label>
                <input
                  type="text"
                  id="codigo"
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value)}
                  maxLength={8}
                  pattern="[A-Za-z0-9]{8}"
                  required
                  className='border block mb-5 p-3 w-full rounded-md text-lg mono text-center'
                />
              </div>
              <button type="submit" className="
                            transition:all 
                            duration-300 
                            ease-in-out 
                            w-full 
                            px-2 
                            py-4 
                          text-white 
                            font-semibold 
                          bg-[#FF5EA3] 
                            rounded-lg 
                          hover:bg-[#FF7DF2] 
                            hover:scale-105 p-3">Activar Restaurante</button>
              {errorMessage && (
                <p className="text-red-500 mt-3 text-center">{errorMessage}</p>
              )}
            </form>
          </div>
        ) : (
          <div className="max-w-1/2 p-4">
            <h2 className='text-xl font-bold mb-2'>Cuenta Activada Exitosamente</h2>
            <p>Tu cuenta fue activada con éxito! Puedes comenzar a disfrutar de Tippit.</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ActivarResto;
